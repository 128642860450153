import React, { useEffect } from 'react';
import { Col, Csku, Input, Row } from '@commonsku/styles';

import { sizeSort } from '../../utils';

import { LabeledSelect } from '../helpers/Select';
import { SmallOnly, Medium } from '../ScreenSize';
import {
  showMinQty,
  showMaxQty,
  hasItemInventory,
  getItemInventory,
  onChangeItemOption,
  AvailableInventory,
  MaximumQuantity,
} from './helpers';
import FreeShopCartItem from './FreeShopCartItem';
import { useSelector } from 'react-redux';
import { useFreeItemQuantityChecker } from '../../hooks/useFreeItemQuantityValidation';

const CartItemOptionSelector = ({
  color_id,
  size_ids,
  focus,
  item,
  quantities,
  template_color,
  onChangeColor,
  onFocus,
  onChangeSize,
  onUpdateSize,
  small = false,
  aggregate,
  force_minimum_qty = false,
  buyInventory = false,
  showInventory = true,
  onChangeFreeShopColor,
  onChangeFreeShopSize,
  is_shop_free,
}) => {
  const selectStyle = {
    display: 'inline-block',
    minWidth: '300px',
    verticalAlign: 'middle',
    marginLeft: '20px',
  };
  if ('color_id' === focus) {
    selectStyle.border = `1px solid ${template_color}`;
  }

  const handleKeyDown = size_id => e => {
    if ('Enter' === e.key) {
      onUpdateSize(size_id);
    }
  };

  const hasInventory = hasItemInventory(item, buyInventory);
  const inventory_sizes = item.inventory_items.map(ii => ii.size_id);
  const sizes = (!item.sizes.length ? [{ size_id: 'TBD', size_name: is_shop_free == 1 ? 'One Size' : 'Quantity' }] : item.sizes.sort((a, b) => sizeSort(a.size_name, b.size_name))).filter(
    s => buyInventory || item.inventory_items.length === 0 || inventory_sizes.includes(s.size_id)
  );
  const inventory_colors = item.inventory_items.map(ii => ii.color_id);
  const colors = item.colors.filter(
    c => buyInventory || item.inventory_items.length === 0 || inventory_colors.includes(c.color_id)
  );

  const getInventory = (size_id) => getItemInventory(item, size_id, color_id, buyInventory);

  const showMinimumQuantity = showMinQty(item, buyInventory, aggregate, force_minimum_qty);
  const showMaximumQuantity = (quantity, maxQuantity) => showMaxQty(buyInventory, hasInventory, quantity, maxQuantity);

  const onChange = size_id => onChangeItemOption(item, size_id, color_id, buyInventory, onChangeSize);
  const color_selected_array = color_id ? [color_id] : item.breakdowns.length > 0 ? [item.breakdowns[0].color_id] : [];
  const color_selected_value = color_selected_array ? color_selected_array[0] : '';
  const size_selected_array = size_ids ? [size_ids] : item.breakdowns.length > 0 ? [item.breakdowns[0].size_id] : [];
  const size_Selected_value = size_selected_array ? size_selected_array[0] : '';

  return (
    <div>
      {!small &&
        <Medium>
          <div data-testid={"medium-cart_item_option_selector-" + item.item_id}>
            {is_shop_free == 1 &&
              <FreeShopCartItem
                item={item}
                sizes={sizes}
                colors={colors}
                template_color={template_color}
                size_Selected_value={size_Selected_value}
                color_selected_array={color_selected_array}
                color_selected_value={color_selected_value}
                onChangeFreeShopColor={onChangeFreeShopColor}
                onChangeFreeShopSize={onChangeFreeShopSize}
              />
            }
            {1 < colors.length && is_shop_free != 1 &&
              <div data-testid={"medium-cart_item_color_container-" + item.item_id} className="row small-12 columns collapse" style={{ padding: 0 }}>
                <Csku
                  forceStyles
                  className="columns"
                  style={{ paddingTop: '0.25rem', paddingBottom: '14px', }}
                  sx={{
                    xs: { width: '100%' },
                    md: { width: '224px' },
                  }}
                >
                  <LabeledSelect
                    name="color-id-select"
                    label='Color'
                    data-testid={"medium-cart_item_color_selector-" + item.item_id}
                    className="csku-select"
                    options={[{ key: '', value: 'Select Color' }].concat(colors.map(c => ({ key: c.color_id, value: c.color_name })))}
                    value={color_id}
                    onChange={v => onChangeColor(v ? v.value : '')}
                    onFocus={() => onFocus('color_id')}
                    isDisabled={1 === colors.length}
                  />
                </Csku>
              </div>}
            {!!color_id && is_shop_free != 1 &&
              <div className="row small-12 columns collapse" style={{ marginTop: '0.5rem', marginBottom: '0.5rem', borderRadius: '3px', padding: 0 }}>
                <div className="medium-12 columns collapse" data-testid={"medium-cart_item_size_container-" + item.item_id}>
                  <ItemSizeInputs
                    sizes={sizes.filter(s => hasInventory || getInventory(s.size_id) == null)}
                    hasInventory={hasInventory}
                    buyInventory={buyInventory}
                    showInventory={showInventory}
                    quantities={quantities}
                    color_id={color_id}
                    onChange={onChange}
                    handleKeyDown={handleKeyDown}
                    onUpdateSize={onUpdateSize}
                    onFocus={onFocus}
                    focus={focus}
                    template_color={template_color}
                    showMaximumQuantity={showMaximumQuantity}
                    getInventory={getInventory}
                  />
                  <Row>
                    <Col xs>
                      {/* {showMinimumQuantity && <span className="excludes">{`Min Quantity: ${item.minimum_quantity}`}</span>} */}
                      {hasInventory && showInventory && <label style={{ marginTop: showMinimumQuantity ? '0.5rem' : '1.85rem' }}>Available in Inventory</label>}
                    </Col>
                  </Row>
                </div>
              </div>}
          </div>
        </Medium>}
      {small && <SmallOnly>
        <div className="edit-product" data-testid={"small-cart_item_option_selector-" + item.item_id}>
          {is_shop_free != 1 && [
            colors.length > 0 && <div data-testid={"small-cart_item_color_container-" + item.item_id} className="editcolor" key="edit-product-color">
              <LabeledSelect
                name="color-id-select"
                label='Color'
                className="csku-select"
                data-testid={"small-cart_item_color_selector-" + item.item_id}
                options={[{ key: '', value: 'Select Color' }].concat(colors.map(c => ({ key: c.color_id, value: c.color_name })))}
                value={color_id}
                onChange={v => onChangeColor(v ? v.value : '')}
                onFocus={() => onFocus('color_id')}
                isDisabled={1 === colors.length}
              />
              {/* {showMinimumQuantity && <span className="excludes">{`Min Quantity: ${item.minimum_quantity}`}</span>} */}
            </div>,
            !!color_id && <div data-testid={"small-cart_item_size_container-" + item.item_id} className="quantity" key="edit-product-quantity">
              <ItemSizeInputs
                sizes={sizes.filter(s => !hasInventory || getInventory(s.size_id) !== null)}
                hasInventory={hasInventory}
                buyInventory={buyInventory}
                showInventory={showInventory}
                quantities={quantities}
                color_id={color_id}
                onChange={onChange}
                handleKeyDown={handleKeyDown}
                onUpdateSize={onUpdateSize}
                onFocus={onFocus}
                focus={focus}
                template_color={template_color}
                showMaximumQuantity={showMaximumQuantity}
                getInventory={getInventory}
              />
            </div>
          ]}
        </div>
        {is_shop_free == 1 &&
          <FreeShopCartItem
            item={item}
            sizes={sizes}
            colors={colors}
            template_color={template_color}
            size_Selected_value={size_Selected_value}
            color_selected_array={color_selected_array}
            color_selected_value={color_selected_value}
            onChangeFreeShopColor={onChangeFreeShopColor}
            onChangeFreeShopSize={onChangeFreeShopSize}
          />
        }
      </SmallOnly>}
    </div>
  );
};

export const CartItemColorOptionSelector = ({
  select_size_id,
  color_id,
  size_ids,
  focus,
  item,
  quantities,
  template_color,
  onFocus,
  onChangeSize,
  onUpdateSize,
  buyInventory = false,
  onChangeFreeShopColor,
  onChangeFreeShopSize,
  is_shop_free,
  textAlign = '',
  freeItemsCount = 1,
  allowMultipleFreeItem = false,
  onUpdateMultiFreeItemError
}) => {
  const selectStyle = {
    display: 'inline-block',
    minWidth: '300px',
    verticalAlign: 'middle',
    marginLeft: '20px',
  };
  if ('color_id' === focus) {
    selectStyle.border = `1px solid ${template_color}`;
  }

  const handleKeyDown = (size_id, multiFreeItemCount = undefined) => e => {
    if ('Enter' === e.key) {
      onUpdateSize(size_id, multiFreeItemCount);
    }
  };
  const hasInventory = hasItemInventory(item, buyInventory);
  const inventory_sizes = item.inventory_items.map(ii => ii.size_id);
  const sizes = (!item.sizes.length ? [{ size_id: 'TBD', size_name: is_shop_free == 1 && !(allowMultipleFreeItem && freeItemsCount > 1) ? 'One Size' : 'Quantity' }] : item.sizes.sort((a, b) => sizeSort(a.size_name, b.size_name))).filter(
    s => buyInventory || item.inventory_items.length === 0 || inventory_sizes.includes(s.size_id)
  );
  const inventory_colors = item.inventory_items.map(ii => ii.color_id);
  const colors = item.colors.filter(
    c => buyInventory || item.inventory_items.length === 0 || inventory_colors.includes(c.color_id)
  );

  const getInventory = (size_id) => getItemInventory(item, size_id, color_id, buyInventory);

  const onChange = size_id => onChangeItemOption(item, size_id, color_id, buyInventory, onChangeSize);
  const color_selected_array = color_id ? [color_id] : item.breakdowns.length > 0 ? [item.breakdowns[0].color_id] : [];
  const color_selected_value = color_selected_array ? color_selected_array[0] : '';
  const size_selected_array = size_ids ? [size_ids] : item.breakdowns.length > 0 ? [item.breakdowns[0].size_id] : [];
  const size_Selected_value = size_selected_array ? size_selected_array[0] : '';

  const handleMultiFreeItemErrorMessage = (message) => onUpdateMultiFreeItemError(message);

  return (
    is_shop_free == 1 && !(allowMultipleFreeItem && freeItemsCount > 1) ? <FreeShopCartItem
      item={item}
      sizes={sizes}
      colors={colors}
      template_color={template_color}
      size_Selected_value={size_Selected_value}
      color_selected_array={color_selected_array}
      color_selected_value={color_selected_value}
      onChangeFreeShopColor={onChangeFreeShopColor}
      onChangeFreeShopSize={onChangeFreeShopSize}
    /> : !!color_id && <ItemSizeInput
      size={sizes.filter(s => hasInventory || getInventory(s.size_id) == null).find(s => s.size_id === select_size_id)}
      buyInventory={buyInventory}
      quantities={quantities}
      color_id={color_id}
      onChange={onChange}
      handleKeyDown={handleKeyDown}
      onUpdateSize={onUpdateSize}
      onFocus={onFocus}
      focus={focus}
      template_color={template_color}
      getInventory={getInventory}
      textAlign={textAlign}
      freeItemsCount={freeItemsCount}
      is_shop_free={is_shop_free}
      allowMultipleFreeItem={allowMultipleFreeItem}
      handleMultiFreeItemErrorMessage={handleMultiFreeItemErrorMessage}
    />
  );
};

const ItemSizeInputs = ({
  sizes,
  hasInventory,
  buyInventory,
  showInventory,
  quantities,
  color_id,
  onChange,
  handleKeyDown,
  onUpdateSize,
  onFocus,
  focus,
  template_color,
  showMaximumQuantity,
  getInventory,
  freeItemsCount,
  is_shop_free,
  allowMultipleFreeItem,
  handleMultiFreeItemErrorMessage
}) => {
  return (
    <Row style={{ paddingLeft: 0 }} data-testid={"item_size_input-row-" + color_id}>
      {sizes.map((s, i) =>
        <Csku as={Col} data-testid={`item_size_input_container-col-${color_id}-${s.size_id}`} key={"sizes-input-"+s.size_id} xs={6} md={4}
          style={{
            xs: {
              paddingLeft: '0px !important',
              paddingRight: `${(i+1)%2 === 0 ? 0 : 24}px !important`,
              paddingBottom: '24px !important',
            },
            md: {
              paddingLeft: '0px !important',
              paddingRight: `${(i+1)%3 === 0 ? 0 : 24}px !important`,
              paddingBottom: '24px !important',
            },
          }}>
          <label style={{ position: 'relative' }}>
            <span style={{ textTransform: '' }} data-testid={`item_size_input_label-${color_id}-${s.size_id}`}>{s.size_name}</span>
            <ItemSizeInput
              size={s}
              buyInventory={buyInventory}
              quantities={quantities}
              color_id={color_id}
              onChange={onChange}
              handleKeyDown={handleKeyDown}
              onUpdateSize={onUpdateSize}
              onFocus={onFocus}
              focus={focus}
              template_color={template_color}
              getInventory={getInventory}
              placeholder={'Quantity'}
              freeItemsCount={freeItemsCount}
              is_shop_free={is_shop_free}
              allowMultipleFreeItem={allowMultipleFreeItem}
              handleMultiFreeItemErrorMessage={handleMultiFreeItemErrorMessage}
            />
            {showMaximumQuantity(
              ((quantities || {})[color_id] || {})[s.size_id] ?? '',
              getInventory(s.size_id)
            ) && <MaximumQuantity />}
            {hasInventory && showInventory && <AvailableInventory label="Inventory: " quantity={getInventory(s.size_id)} />}
          </label>
        </Csku>
      )}
    </Row>
  );
};

const ItemSizeInput = ({
  size,
  buyInventory,
  quantities,
  color_id,
  onChange,
  handleKeyDown,
  onUpdateSize,
  onFocus,
  focus,
  template_color,
  getInventory,
  textAlign='',
  placeholder='Quantity',
  freeItemsCount,
  is_shop_free,
  allowMultipleFreeItem,
  handleMultiFreeItemErrorMessage
}) => {
  const itemQuantity = ((quantities || {})[color_id] || {})[size.size_id] || '';
  const cart = useSelector(state => state.temp.cart);
  const totalQuantity = Object.values(cart).flat().reduce((sum, item) => sum + item.quantity, 0);
  const {currentFreeItemsQuantity, errorMessage} = useFreeItemQuantityChecker(itemQuantity, totalQuantity, freeItemsCount);

  useEffect(()=> {
    if (is_shop_free == 1 && allowMultipleFreeItem){
      handleMultiFreeItemErrorMessage(errorMessage);
    }
}, [errorMessage]);

  return (
    <Input
      type="text"
      value={is_shop_free == 1 && allowMultipleFreeItem ? currentFreeItemsQuantity : itemQuantity}
      onChange={onChange(size.size_id)}
      onKeyDown={handleKeyDown(size.size_id, currentFreeItemsQuantity)}
      onBlur={() => onUpdateSize(size.size_id, currentFreeItemsQuantity)}
      onFocus={() => onFocus(size.size_id)}
      style={{
        marginBottom: 0,
        borderRadius: 5,
        ...(focus === size.size_id ? { border: `1px solid ${template_color}`, marginBottom: 0 } : {}),
        textAlign,
      }}
      disabled={!buyInventory && getInventory(size.size_id) === 0}
      placeholder={placeholder}
      id={`item_size_input_input-${color_id}-${size.size_id}`}
    />
  );
};

export default CartItemOptionSelector;
