export function getOrderTotal({ cart }) {
  return cart.items.reduce((t, i) => parseFloat(t) + parseFloat(i.subtotal), 0.00000);
}

export function isTotalOk({ per_checkout_limit, orderTotal }) {
  return per_checkout_limit ?
    orderTotal <= per_checkout_limit :
    true;
}

export function isArtworkOk({ client_id, cart }) {
  return !!client_id || cart.items.reduce((ok, i) => ok && i.artworks.reduce((ok2, a) => ok2 && !!a.image, true), true);
}

export function isMinimumQuantityOk({ buy_inventory, force_minimum_qty, aggregate, cart }) {
  const itemInCart = !!cart.items.length;
  return itemInCart && cart.items.reduce((ok, i) => {
    if (!ok) {
      return ok;
    }
    if (!buy_inventory && i.inventory_items.length > 0) {
      // If this is an item with inventory and we are not buying inventory do nothing
      return i.quantity > 0;
    } else if ((aggregate == 1 && force_minimum_qty == 1) || aggregate == 0) {
      return i.quantity >= i.minimum_quantity;
    } else {
      return i.quantity > 0;
    }
  }, true);
}

export function isShopQuantityOk({ is_free_shop, items, free_items_count=1, allow_multiple_free_item=1, }) {
  if (+is_free_shop !== 1 || items.length === 0) {
    return true;
  }

  let count = 0;
  let quantities = {};
  for (const item of items) {
    if (+item.quantity === 0) {
      continue;
    }

    if (!quantities[item.item_id]) {
      quantities[item.item_id] = 0;
    }
    count += +item.quantity;
    if (count > free_items_count) {
      return false;
    }
    quantities[item.item_id] = +(quantities[item.item_id] ?? '0') + +item.quantity;
    if (+allow_multiple_free_item !== 1 && quantities[item.item_id] > 1) {
      return false;
    }
  }

  return true;
}

