import { useRef } from "react";

export const useFreeItemQuantityChecker = (
  itemQuantity: number,
  cartQuantity: number,
  maxAllowedQuantity: number
) => {
  const currentQuantityRef = useRef(itemQuantity);
  const remainingAllowanceRef = useRef(maxAllowedQuantity - cartQuantity);

  if (
    itemQuantity >
    remainingAllowanceRef.current + currentQuantityRef.current
  ) {
    return {
      currentFreeItemsQuantity: currentQuantityRef.current,
      errorMessage: `You can only have a maximum of ${maxAllowedQuantity} free products.`,
    };
  } else {
    return {
      currentFreeItemsQuantity: itemQuantity,
      errorMessage: null,
    };
  }
};
