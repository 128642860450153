import { ShopIcon } from "@commonsku/styles";
import React from "react";
import styled from "styled-components";

interface ClosedShopStaticPageProps {
  shopName: string;
  contactEmail: string;
  contactName: string;
}

const ClosedShopContainer = styled.div`
  background: white;
  padding: 100px 50px;
  margin-top: 100px;
  border-radius: 10px;
  height: 100vh;
`;

const Heading = styled.b`
  color: #2a4d63;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  z-index: 100;
  width: 50%;
`;

const Message = styled.p`
  color: #597486;
  font-size: 24px;
  font-style: normal;
  line-height: 40px;
  font-weight: 450;
  position: relative;
  z-index: 100;
`;

const Link = styled.a`
  color: #00a0b6;
  text-decoration: none;
`;

const SvgContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const ClosedShopStaticPage = ({
  shopName,
  contactEmail,
  contactName,
}: ClosedShopStaticPageProps) => (
  <ClosedShopContainer>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <ShopIcon
        size="huge"
        color="#2A4D63"
        style={{ zIndex: 100 }}
        width={124}
        height={124}
      />
      <Heading>
        Unfortunately, {shopName} is not available for orders at the moment.
      </Heading>
      {contactEmail && (
        <Message>
          Contact {contactName} at{" "}
          <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link> for more
          details.
        </Message>
      )}
    </div>
    <SvgContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="310"
        viewBox="0 0 1510 310"
        fill="none"
        preserveAspectRatio="none"
      >
        <path
          d="M858 52.2161C498 -49.6986 200.667 20.7424 0 68.7022V310H1510V52.2166L1508.43 52.7138C1352.99 101.997 1202.45 149.728 858 52.2161Z"
          fill="#EDF4F7"
        />
      </svg>
    </SvgContainer>
  </ClosedShopContainer>
);
export default ClosedShopStaticPage;
