import React from 'react';
import {
  Col,
  Csku,
  Row,
  colors,
  fontFamilies,
} from '@commonsku/styles';
import { matchPath, useNavigate } from 'react-router-dom';

import { TemplateButton, formatNumber } from '../helpers';
import CartErrors from './CartErrors';
import { useDispatch } from 'react-redux';
import { createCloseCart } from '../../../actions/shop';
import { usePublicViewEntityContext } from '../../../context/PublicViewEntityProvider';

const CartFooter = React.memo(({
  cart,
  order,
  totalOk,
  qtyOK,
  freeItemsCount,
  isCheckout,
  orderTotal,
  itemInCart,
  isAnyItemOpen,
  template_color,
  checkoutDisabled,
  onClose,
  onCheckout,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    entityId,
    basePathname,
    entityIsFree: is_shop_free,
    perCheckoutLimit: per_checkout_limit,
    entityLocation,
  } = usePublicViewEntityContext();

  const onCloseCart = () => {
    Promise.resolve(dispatch(createCloseCart(entityId)))
    .then(() => navigate(entityLocation));
  };

  const totalQuantity = cart.items.reduce((sum, item) => sum + item.quantity, 0);
  const isKeepShoppingDisabled = !qtyOK || (+is_shop_free === 1 && totalQuantity === freeItemsCount);

  return (
    <Csku forceStyles as={Col} xs sx={{
      xs: {
        marginBottom: 72,
        position: 'sticky',
        top: 'auto',
        bottom: 0,
        background: colors.neutrals[20],
        ...(itemInCart ? {} : { display: 'none' }),
      },
      md: { marginBottom: 0, },
    }}>
      <div style={{ borderBottom: '2px solid var(--color-neutrals-60)', }}></div>
      <Csku as={Row} style={{
        xs: {
          padding: '16px !important',
          ...(isCheckout ? { textAlign: 'center' } : {}),
        },
        md: {
          padding: '16px !important',
        },
      }}>
        <Col xs smOffset={1.5} smOffsetRight={!isAnyItemOpen ? 2 : 1.5}>
          {+is_shop_free === 1 ? (isKeepShoppingDisabled ? <Row>
            <Col xs style={{ textAlign: 'left' }}>
              You have collected the maximum number of free items.
              <br />To select a different product, please delete an existing item in the cart.
            </Col>
          </Row> : null) : <Row>
            <Col xs={6} style={{ textAlign: 'left' }}>
              <span className="order-total-label" style={{ fontSize: '1rem', marginRight: '20px', color: 'var(--color-neutrals-90)' }}>
                Order Total{per_checkout_limit ? <span style={{
                  fontFamily: fontFamilies.regular,
                  fontSize: '14px',
                  paddingLeft: '7px',
                  color: 'var(--color-neutrals-80)'
                }}>(max {order.currency_symbol}{formatNumber(per_checkout_limit, true)})</span> : null}:
              </span>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <span className="order-total" style={{
                fontSize: '1rem',
                color: !totalOk ? 'var(--color-errors-main)' : 'var(--color-neutrals-90)',
              }}>
                {order.currency_symbol}{formatNumber(orderTotal, true)}
              </span>
            </Col>
            <Col xs style={{ textAlign: 'left' }}>
              <span className="excludes" style={{ color: 'var(--color-neutrals-90)' }}>excludes shipping, freight and taxes</span>
            </Col>
          </Row>}
        </Col>
        <Col xs smOffset={1.5} smOffsetRight={!isAnyItemOpen ? 2 : 1.5} style={{ textAlign: 'center', paddingTop: 14, }}>
          <CartErrors cart={cart} />
        </Col>
        <Col xs xsOffset={2} smOffset={1.5} xsOffsetRight={2} smOffsetRight={2}  style={{ paddingTop: 14 }}>
          {isCheckout
            ? <TemplateButton
              id="btn"
              className='return_to_checkout_btn'
              template_color={template_color}
              style={{
                marginRight: 10,
                width: '100%',
                textAlign: 'center',
              }}
              onClick={onClose}
            >Return to Checkout</TemplateButton>
            : <>
              <Csku
                as={TemplateButton}
                id="btn"
                className='keep_shopping_btn'
                template_color={template_color}
                isSecondary
                style={{
                  xs: {
                    marginRight: '10px !important',
                    marginBottom: '14px !important',
                    width: '100% !important',
                    textAlign: 'center !important',
                  },
                  sm: { width: '48.5% !important', },
                }}
                onClick={() => {
                  if(isKeepShoppingDisabled) return;
                  const cartMatch = matchPath({ path: `${basePathname}/:parent_id/cart` }, window.location.pathname);
                  if (cartMatch) {
                    onCloseCart();
                  } else {
                    onClose();
                  }
                }}
                disabled={isKeepShoppingDisabled}
              >Keep Shopping</Csku>
              <Csku
                as={TemplateButton}
                id="btn"
                className="continue go_to_checkout_btn"
                template_color={template_color}
                style={{
                  xs: {
                    width: '100% !important',
                    textAlign: 'center !important',
                  },
                  sm: { width: '48.5% !important', },
                }}
                disabled={checkoutDisabled}
                onClick={() => checkoutDisabled ? null : onCheckout()}
              >Go to Checkout</Csku>
            </>}
        </Col>
      </Csku>
    </Csku>
  );
});

export default CartFooter;
