import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { formatMoney, oauth } from "../../../utils";
import { TemplateButton } from "../helpers";

import AvalaraShopTaxButton from "./AvalaraShopTaxButton";
import { createCloseCart} from "../../../actions/shop";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import { isShopQuantityOk } from "../Cart/helpers";
import { Typography } from "@commonsku/styles";

const STRIPE_MINIMUM = 0.5;

export default function CheckoutButtons({
  order,
  cart,
  error,
  checkoutTotal,
  discountValue,
  shippingAddress,
  shipping_cost,
  avalara_summary,

  handleSubmitOrder,
  onChangeAvalaraSummary,
  onChangeAddressTax,
  onError,

  isAvalara = false,
  isDisabled = false,
  show_for_size = "",
  estimateTaxByAddress = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    entity,
    entityId,
    entityAggregate,
    entityIsFree,
    entityBuyInventory,
    entityLocation,
    freeItemsCount,
  } = usePublicViewEntityContext();
  const { templateColor: template_color } = usePublicViewTemplateDataContext();
  const qtyOK = isShopQuantityOk({ is_free_shop: entityIsFree, items: cart.items, free_items_count: entity.free_items_count, allow_multiple_free_item: entity.allow_multiple_free_item });
  const totalQuantity = cart.items.reduce((sum, item) => sum + item.quantity, 0);
  const isKeepShoppingDisabled = !qtyOK || (+entityIsFree === 1 && totalQuantity === freeItemsCount);

  const new_class =
    show_for_size === "small" && +entityAggregate === 1
      ? "small-12 row show-for-small-only"
      : show_for_size === "large" && +entityAggregate === 1
      ? "small-12 row show-for-medium"
      : "small-12 row";
  const isCreditCard =
    entity.allow_credit_card_checkout == 1 &&
    checkoutTotal > 0 &&
    !entityBuyInventory &&
    +entityIsFree !== 1;
  const minimumAmount = STRIPE_MINIMUM;
  const minimumReached =
    isCreditCard &&
    entity.company_data?.credit_card_software === "STRIPE" &&
    checkoutTotal < minimumAmount
      ? false
      : true;

  let payButton = (
    <TemplateButton
      template_color={template_color}
      style={{ width: "100%" }}
      disabled={isDisabled || (+entityIsFree === 1 && totalQuantity > freeItemsCount)}
      onClick={(e) => {
        if (isDisabled || (+entityIsFree === 1 && totalQuantity > freeItemsCount)) {
          return;
        }
        if (estimateTaxByAddress && !shippingAddress.tax_id) {
          oauth("GET", "address/get-shop-tax-from-address", {
            shop_id: entityId,
            ...shippingAddress,
          })
            .then((res) => {
              onChangeAddressTax(res?.json?.tax);
            })
            .catch((err) => {
              const errorMsg = err?.json?.error || "No tax found";
              onError?.(errorMsg);
              console.log(errorMsg);
            });
          return;
        }
        handleSubmitOrder(e);
      }}
    >
      {estimateTaxByAddress && !shippingAddress.tax_id
        ? "Estimate Tax"
        : isCreditCard
        ? "Pay by Credit Card"
        : "Submit Order"}
    </TemplateButton>
  );

  const onCloseCart = () => {
    Promise.resolve(
      dispatch(createCloseCart(entityId))
    ).then(() => {
      navigate(entityLocation);
    });
  };

  if (!minimumReached) {
    payButton = (
      <span style={{ color: "red" }}>
        Minimum Payment of {order.currency_symbol}
        {formatMoney(minimumAmount)} is required to finalize this order. Please
        add more in the cart.
      </span>
    );
  }

  if (cart.items.length === 0) {
    payButton = (
      <span style={{ color: "red" }}>
        Your cart is empty. Please choose{" "}
        {+entityIsFree === 1 && freeItemsCount <= 1 ? "an item" : "some items"} for your cart.
      </span>
    );
  }

  return (
    <div
      key="checkout-buttons"
      className={new_class}
      style={{ marginBottom: "1rem" }}
    >
      {+entityIsFree === 1 && isKeepShoppingDisabled && <Typography.Span xs style={{ textAlign: 'left', marginBottom: 10, display: 'inline-block' }}>
      You have collected the maximum number of  free items. To select a different product, please delete an existing item in the cart. To submit order as is, please fill in the required checkout information
      </Typography.Span>}
      <div className="large-5 columns keep-shopping-btn-container">
        <TemplateButton
          isSecondary
          template_color={template_color}
          onClick={() => {
            if(isKeepShoppingDisabled) return;
            onCloseCart();
          }}
          style={{
            width: "100%",
            marginBottom: "1rem",
            padding: 12,
          }}
          disabled={isKeepShoppingDisabled}
        >Keep Shopping</TemplateButton>
      </div>
      <div className="large-7 columns" style={{ padding: 0 }}>
        {isAvalara && avalara_summary === false ? (
          <AvalaraShopTaxButton
            disabled={isDisabled}
            style={{ width: "100%" }}
            items={cart.items}
            shipping_cost={shipping_cost}
            address={shippingAddress}
            discount={discountValue}
            onChange={(result) => {
              onChangeAvalaraSummary(result);
            }}
          />
        ) : (
          payButton
        )}
      </div>
    </div>
  );
}
