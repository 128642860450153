import React, { useCallback, useEffect, useMemo } from 'react';
import { get, first, upperFirst } from 'lodash';
import { Col, Csku, Input, Row } from '@commonsku/styles';

import { sizeSort } from '../../utils';
import {
  getOptionsFromSkus,
  getSkuInventory,
  getItemSkus,
  hasSkuInventory,
  AvailableInventory,
  MaximumQuantity,
} from './helpers';
import { SmallOnly, Medium } from '../ScreenSize';
import { LabeledSelect } from '../helpers/Select';
import FreeShopItemSkuSelector from './FreeShopItemSkuSelector';
import { useFreeItemQuantityChecker } from '../../hooks/useFreeItemQuantityValidation';
import { useSelector } from 'react-redux';

function getParsedData({
  value,
  skus,
  options,
  axes,
  getSkuFromOptions,
}) {
  const winnowingAxes = axes.filter(a => a !== 'size' && options[a].length > 0);
  const winnowingAxesOptions = axes.reduce(
    (wao, a) => ({
      ...wao,
      [a]: options[a].map(o => ({ key: o, value: o }))
    }),
    {}
  );

  let winnowed = (get(winnowingAxesOptions, [first(winnowingAxes)]) || []).length <= 1 || Object.getOwnPropertyNames(value).length && value[Object.getOwnPropertyNames(value)[0]] !== '' ? true : false;

  const firstAxis = axes.shift();
  const firstAxisOptions = (options[firstAxis] || []).map(o => ({ key: o, value: o }));
  const quantityAxis = axes.includes('size') ? 'size' : axes.includes('dimension') ? 'dimension' : 'sku';
  const sizeaxes = axes.filter(a => a == quantityAxis && options[a].length > 0);
  const filteredOptions = winnowed ?
    getOptionsFromSkus(
      skus.filter(
        sku => !!sku.options.filter(o => winnowingAxes.reduce((t, a) => t || (o.option_axis === a && o.option_name === value[a]), false)).length
      )
    ) :
    [];

  if (!filteredOptions.size || !filteredOptions.size.length) {
    const selectedSkus = getSkuFromOptions(value);
    if (1 === selectedSkus.length) {
      filteredOptions.sku = ['Quantity'];
    } else if (selectedSkus.length > 1) {
      filteredOptions.sku = selectedSkus.map(
        product_sku_id => skus.filter(
          sku => product_sku_id === sku.product_sku_id
        ).map(
          sku => !!sku.description ? sku.description : sku.sku
        )[0]
      );
    } else {
      winnowed = false;
    }
  }

  return {
    winnowingAxes,
    winnowingAxesOptions,
    winnowed,
    quantityAxis,
    sizeaxes,
    filteredOptions,
  };
}

const useGetSkusFromOptions = ({
  value,
  skus,
  quantityAxis,
}) => {
  const skuOptions = useMemo(
    () => {
      const baseOptions = skus.reduce(
        (o, sku) => o.concat(sku.options),
        []
      ).reduce(
        (o, option) => ({
          ...o,
          [option.option_axis]: Array.from(new Set((o[option.option_axis] || []).concat(option.option_name))).sort(
            'size' === option.option_axis ? sizeSort : undefined
          )
        }),
        {}
      );
      // const hasSizeAxis = Object.keys(baseOptions).includes('size');
      const axes = Object.keys(baseOptions).filter(a => 'dimension' !== a).sort();
      const options = Object.keys(baseOptions).filter(
        k => axes.includes(k)
      ).reduce(
        (o, k) => ({ ...o, [k]: baseOptions[k] }),
        {}
      );
      return options;
    },
    [skus]
  );
  const isOptionSet = useCallback(
    (value, option) => {
      return skuOptions[option.option_axis].length === 1 || value[option.option_axis] === option.option_name;
    },
    [skuOptions]
  );
  const getSkuFromOptions = useCallback(
    (option, qtyAxis=null) => {
      const val = { ...value, [qtyAxis || quantityAxis]: option };
      const axes = Object.keys(skuOptions);
      const sku = skus.filter(
        sku => sku.options.filter(o => axes.includes(o.option_axis)).every(o => isOptionSet(val, o))
      ).filter(
        sku => !val.sku || val.sku === 'Quantity' || val.sku === (sku.description || sku.sku)
      ).map(
        sku => sku.product_sku_id
      );

      return sku;
    },
    [skus, skuOptions, value, isOptionSet, quantityAxis]
  );
  return getSkuFromOptions;
};

const CartItemSkuSelector = ({
  focus,
  item,
  quantities,
  template_color,
  value,
  onChangeFirstAxis,
  onFocus,
  onChangeQuantity,
  onUpdateQuantity,
  small = false,
  aggregate,
  force_minimum_qty = false,
  buyInventory = false,
  showInventory = true,
  is_shop_free,
  onChangeColorAxisFreeShop,
  onChangeSizeAxisFreeShop,
  availableOptions,
  availableOptionsColor,
}) => {
  const {
    skus,
    options,
    axes
  } = getItemSkus(item, buyInventory);
  const quantityAxis = useMemo(
    () => axes.includes('size') ? 'size' : axes.includes('dimension') ? 'dimension' : 'sku',
    [axes]
  );
  const getSkuFromOptions = useGetSkusFromOptions({ quantityAxis, skus, value });

  const {
    winnowingAxes,
    winnowingAxesOptions,
    winnowed,
    sizeaxes,
    filteredOptions,
  } = getParsedData({
    value,
    skus,
    options,
    axes,
    is_shop_free,
    item,
    buyInventory,
    getSkuFromOptions,
  });

  const selectStyle = {
    display: 'inline-block',
    minWidth: '300px',
    verticalAlign: 'middle',
    marginLeft: '20px'
  };

  const hasInventory = hasSkuInventory(item, buyInventory);
  const showMinimumQuantity = (!!buyInventory || item.inventory_items.length === 0) && (!aggregate || force_minimum_qty);
  const handleKeyDown = (axis, option) => e => {
    if ('Enter' === e.key) {
      onUpdateQuantity(axis, option);
    }
  };
  const getInventory = (product_sku_id) => getSkuInventory(item, buyInventory, product_sku_id);
  const showMaximumQuantity = (quantity, maxQuantity) => hasInventory && !buyInventory && quantity === maxQuantity;
  const onChange = (qtyAxis, option) => e => {
    if (qtyAxis === null) {
      qtyAxis = quantityAxis;
    }

    const newValue = e.target.value.replace(/[^0-9]/g, '');
    if (hasInventory && !buyInventory) {
      const maxValue = getSkuInventory(item, hasInventory, getSkuFromOptions(option, qtyAxis)[0]);
      if (newValue > maxValue) {
        return onChangeQuantity(qtyAxis, option, maxValue, {});
      }
    }
    return onChangeQuantity(qtyAxis, option, newValue, {});
  };
  const selectedFreeShopValue = (a) => Array.isArray(value[a]) ? value[a] : [value[a]];

  const winnowingAxesOption = get(winnowingAxesOptions, [first(winnowingAxes)]) || [];

  return (
    <div>
      {!small && <Medium>
        {is_shop_free == 1 && <FreeShopItemSkuSelector
          item={item}
          value={value}
          template_color={template_color}
          sizeaxes={sizeaxes}
          winnowingAxes={winnowingAxes}
          winnowingAxesOptions={winnowingAxesOptions}
          winnowingAxesOption={winnowingAxesOption}
          availableOptions={availableOptions}
          availableOptionsColor={availableOptionsColor}
          onChangeSizeAxisFreeShop={onChangeSizeAxisFreeShop}
          onChangeColorAxisFreeShop={onChangeColorAxisFreeShop}
          selectedFreeShopValue={selectedFreeShopValue}
        />}
        {is_shop_free != 1 && <div className="medium-cart_item_sku_selector">
          {winnowingAxesOption.length > 1 && winnowingAxes.map(a =>
            <div key={a} className="row small-12 columns collapse medium-cart_item_sku_axis_option_container" style={{ padding: 0 }}>
              <Csku forceStyles
                className="columns"
                style={{ paddingTop: '0.25rem', paddingBottom: '14px', }}
                sx={{
                  xs: { width: '100%' },
                  md: { width: '224px' },
                }}
              >
                <LabeledSelect
                  name={a + "-id-select"}
                  label={upperFirst(a)}
                  className="csku-select medium-cart_item_sku_axis_option_select"
                  options={[{ key: '', value: `Select ${a}` }].concat(winnowingAxesOptions[a])}
                  value={value[a]}
                  onChange={option => onChangeFirstAxis(a, option ? option.value : '')}
                  onFocus={() => onFocus(a)}
                  isDisabled={1 === winnowingAxesOptions[a].length}
                />
              </Csku>
            </div>
          )}
          {(winnowed || value?.[first(winnowingAxes)]) &&
            <div className="row small-12 columns collapse" style={{ marginTop: '0.5rem', marginBottom: '0.5rem', borderRadius: '3px', padding: 0 }}>
              <div className="medium-12 columns collapse">
                {winnowingAxes.map(
                  a => <label key={a} style={{ paddingTop: '0.5rem', textTransform: 'capitalize' }}>{value[a]}</label>
                )}
                {showMinimumQuantity && <span className="excludes">{`Minimum Quantity of ${item.minimum_quantity}`}</span>}
                {hasInventory && showInventory && <label style={{ marginTop: showMinimumQuantity ? '0.5rem' : '1.85rem' }}>Available in Inventory</label>}
              </div>
              <div className="medium-12 columns collapse medium-cart_item_sku_axis_qty_container">
                <ItemAxisQuantitites
                  focus={focus}
                  quantityAxis={quantityAxis}
                  quantities={quantities}
                  filteredOptions={filteredOptions[quantityAxis] ? filteredOptions[quantityAxis].sort('size' === quantityAxis ? sizeSort : undefined) : [{
                    itemQuantity: "",
                    option: 'Quantity',
                    skuFromOptions: getSkuFromOptions(''),
                    skuInventory: null,
                  }]}
                  hasInventory={hasInventory}
                  buyInventory={buyInventory}
                  showInventory={showInventory}
                  template_color={template_color}
                  getInventory={getInventory}
                  getSkuFromOptions={getSkuFromOptions}
                  onChange={onChange}
                  handleKeyDown={handleKeyDown}
                  onUpdateQuantity={onUpdateQuantity}
                  onFocus={onFocus}
                  showMaximumQuantity={showMaximumQuantity}
                  winnowingAxesOption={winnowingAxesOption}
                />
              </div>
            </div>}
        </div>}
      </Medium>}

      {small && <SmallOnly>
        {is_shop_free == 1 && <FreeShopItemSkuSelector
          item={item}
          value={value}
          template_color={template_color}
          sizeaxes={sizeaxes}
          winnowingAxes={winnowingAxes}
          winnowingAxesOptions={winnowingAxesOptions}
          winnowingAxesOption={winnowingAxesOption}
          availableOptions={availableOptions}
          availableOptionsColor={availableOptionsColor}
          onChangeSizeAxisFreeShop={onChangeSizeAxisFreeShop}
          onChangeColorAxisFreeShop={onChangeColorAxisFreeShop}
          selectedFreeShopValue={selectedFreeShopValue}
        />}
        {is_shop_free != 1 && <div className="edit-product small-cart_item_sku_selector">
          {winnowingAxes.map(a =>
            <div key={a} className="editcolor">
              <label id="bld">
                <LabeledSelect
                  name={a + "-id-select"}
                  label={upperFirst(a)}
                  className="csku-select small-cart_item_sku_axis_option_select"
                  style={{ ...selectStyle, border: focus === a ? `1px solid ${template_color}` : 'inherit' }}
                  options={[{ key: '', value: `Select ${a}` }].concat(winnowingAxesOptions[a])}
                  value={value[a]}
                  onChange={option => onChangeFirstAxis(a, option ? option.value : '')}
                  onFocus={() => onFocus(a)}
                  isDisabled={1 === winnowingAxesOptions[a].length}
                />
              </label>
              {showMinimumQuantity && <span className="excludes">{`Minimum Quantity of ${item.minimum_quantity}`}</span>}
            </div>)}
          {(winnowed || value?.[first(winnowingAxes)]) && is_shop_free != 1 &&
            <div className="quantity small-cart_item_sku_axis_qty_container">
              <ItemAxisQuantitites
                focus={focus}
                quantityAxis={quantityAxis}
                quantities={quantities}
                filteredOptions={filteredOptions[quantityAxis] ? filteredOptions[quantityAxis] : [{
                  itemQuantity: "",
                  option: 'Quantity',
                  skuFromOptions: getSkuFromOptions(''),
                  skuInventory: null,
                }]}
                hasInventory={hasInventory}
                buyInventory={buyInventory}
                showInventory={showInventory}
                template_color={template_color}
                getInventory={getInventory}
                getSkuFromOptions={getSkuFromOptions}
                onChange={onChange}
                handleKeyDown={handleKeyDown}
                onUpdateQuantity={onUpdateQuantity}
                onFocus={onFocus}
                showMaximumQuantity={showMaximumQuantity}
                winnowingAxesOption={winnowingAxesOption}
              />
            </div>}
        </div>}
      </SmallOnly>}
    </div>
  );
};

export const CartItemColorSkuSelector = ({
  selected_product_sku_id,
  focus,
  item,
  quantities,
  template_color,
  value,
  onFocus,
  onChangeQuantity,
  onUpdateQuantity,
  buyInventory = false,
  is_shop_free,
  onChangeColorAxisFreeShop,
  onChangeSizeAxisFreeShop,
  availableOptions,
  availableOptionsColor,
  textAlign = '',
  freeItemsCount = 1,
  allowMultipleFreeItem = false,
  onUpdateMultiFreeItemError
}) => {
  const {
    skus,
    options,
    axes
  } = getItemSkus(item, buyInventory);

  const quantityAxis = useMemo(
    () => axes.includes('size') ? 'size' : axes.includes('dimension') ? 'dimension' : 'sku',
    [axes]
  );
  const getSkuFromOptions = useGetSkusFromOptions({ quantityAxis, skus, value });

  const {
    winnowingAxes,
    winnowingAxesOptions,
    winnowed,
    sizeaxes,
    filteredOptions,
  } = useMemo(
    () => getParsedData({
      value,
      skus,
      options,
      axes,
      is_shop_free,
      item,
      buyInventory,
      selected_product_sku_id,
      getSkuFromOptions
    }),
    [
      value,
      skus,
      options,
      axes,
      is_shop_free,
      item,
      buyInventory,
      selected_product_sku_id,
      getSkuFromOptions,
    ]
  );

  const hasInventory = hasSkuInventory(item, buyInventory);
  const handleKeyDown = (axis, option, multiFreeItemCount = undefined) => e => {
    if ('Enter' === e.key) {
      onUpdateQuantity(axis, option, multiFreeItemCount);
    }
  };
  const getInventory = useCallback(
    () => (product_sku_id) => getSkuInventory(item, buyInventory, product_sku_id),
    [item, buyInventory]
  );
  const onChange = (qtyAxis, option) => e => {
    if (qtyAxis === null) {
      qtyAxis = quantityAxis;
    }

    const newValue = e.target.value.replace(/[^0-9]/g, '');
    if (hasInventory && !buyInventory) {
      const skuFromOptions = getSkuFromOptions(option, qtyAxis);
      const maxValue = getSkuInventory(item, hasInventory, skuFromOptions[0]);
      if (newValue > maxValue) {
        return onChangeQuantity(qtyAxis, option, maxValue, value);
      }
    }
    return onChangeQuantity(qtyAxis, option, newValue, value);
  };
  const selectedFreeShopValue = (a) => Array.isArray(value[a]) ? value[a] : [value[a]];

  const handleMultiFreeItemErrorMessage = (message) => onUpdateMultiFreeItemError(message);

  const option = useMemo(
    () => (filteredOptions[quantityAxis]
        ? filteredOptions[quantityAxis].sort('size' === quantityAxis ? sizeSort : undefined)
        : []
    ).find(op => op === value[quantityAxis]),
    [filteredOptions, quantityAxis, value]
  );
  const winnowingAxesOption = useMemo(
    () => get(winnowingAxesOptions, [first(winnowingAxes)]) || [],
    [winnowingAxesOptions, winnowingAxes]
  );

  const skuFromOptions = useMemo(
    () => getSkuFromOptions(option),
    [getSkuFromOptions, option],
  );
  const skuInventory = useMemo(
    () => getInventory(skuFromOptions[0]),
    [getInventory, skuFromOptions]
  );
  const itemQuantity = quantities[skuFromOptions[0]] || '';

  return (
    is_shop_free == 1 && !(allowMultipleFreeItem && freeItemsCount > 1) ? <FreeShopItemSkuSelector
      item={item}
      value={value}
      template_color={template_color}
      sizeaxes={sizeaxes}
      winnowingAxes={winnowingAxes}
      winnowingAxesOptions={winnowingAxesOptions}
      winnowingAxesOption={winnowingAxesOption}
      availableOptions={availableOptions}
      availableOptionsColor={availableOptionsColor}
      onChangeSizeAxisFreeShop={onChangeSizeAxisFreeShop}
      onChangeColorAxisFreeShop={onChangeColorAxisFreeShop}
      selectedFreeShopValue={selectedFreeShopValue}
    /> : (winnowed || value?.[first(winnowingAxes)]) && <ItemAxisQuantititesInput
      option={option}
      focus={focus}
      quantityAxis={quantityAxis}
      quantities={quantities}
      buyInventory={buyInventory}
      template_color={template_color}
      itemQuantity={itemQuantity}
      skuInventory={skuInventory}
      onChange={onChange}
      handleKeyDown={handleKeyDown}
      onUpdateQuantity={onUpdateQuantity}
      onFocus={onFocus}
      winnowingAxesOption={winnowingAxesOption}
      textAlign={textAlign}
      placeholder={'Quantity'}
      allowMultipleFreeItem={allowMultipleFreeItem}
      freeItemsCount={freeItemsCount}
      is_shop_free={is_shop_free}
      handleMultiFreeItemErrorMessage={handleMultiFreeItemErrorMessage}
    />
  );
};

function ItemAxisQuantitites({
  focus,
  quantityAxis,
  quantities,
  filteredOptions,
  hasInventory,
  buyInventory,
  showInventory,
  template_color,
  getInventory,
  getSkuFromOptions,
  onChange,
  handleKeyDown,
  onUpdateQuantity,
  onFocus,
  showMaximumQuantity,
  allowMultipleFreeItem,
  freeItemsCount,
  is_shop_free,
  handleMultiFreeItemErrorMessage
}) {
  const options = useMemo(
    () => {
      const result = filteredOptions
        .map(option => {
          const op = typeof option === 'string' ? option : option?.option;
          const skuFromOptions = getSkuFromOptions(op);
          const skuInventory = getInventory(skuFromOptions[0]);
          const itemQuantity = quantities[skuFromOptions[0]] || '';
          return { option: op, skuFromOptions, skuInventory, itemQuantity };
        })
        .filter(option => !hasInventory || option.skuInventory !== null);
      return result;
    },
    [filteredOptions, getSkuFromOptions, getInventory, quantities, hasInventory]
  );

  return (
    <Row style={{ paddingLeft: 0 }} className="item_axis_quantities_container">
      {options
        .map(({ option, skuFromOptions, skuInventory, itemQuantity }, i) => (
          <Csku as={Col} key={`axis-${quantityAxis}-option-${option}`} className="sizes item_axis_quantity_container" xs={6} md={4}
            style={{
              xs: {
                paddingLeft: '0px !important',
                paddingRight: `${(i+1)%2 === 0 ? 0 : 24}px !important`,
                paddingBottom: '24px !important',
              },
              md: {
                paddingLeft: '0px !important',
                paddingRight: `${(i+1)%3 === 0 ? 0 : 24}px !important`,
                paddingBottom: '24px !important',
              },
            }}>
            <label style={{ position: 'relative' }}>
              <span className='item_axis_quantity_label'>{option}</span>
              <ItemAxisQuantititesInput
                option={option}
                focus={focus}
                quantityAxis={quantityAxis}
                buyInventory={buyInventory}
                template_color={template_color}
                itemQuantity={itemQuantity}
                skuInventory={skuInventory}
                onChange={onChange}
                handleKeyDown={handleKeyDown}
                onUpdateQuantity={onUpdateQuantity}
                onFocus={onFocus}
                placeholder={'Quantity'}
                allowMultipleFreeItem={allowMultipleFreeItem}
                freeItemsCount={freeItemsCount}
                is_shop_free={is_shop_free}
                handleMultiFreeItemErrorMessage={handleMultiFreeItemErrorMessage}
              />
              {showMaximumQuantity(quantities[skuFromOptions[0]] ?? '', skuInventory) && <MaximumQuantity />}
              {hasInventory && showInventory && <AvailableInventory label="Inventory: " quantity={skuInventory} />}
            </label>
          </Csku>
      ))}
    </Row>
  );
}

function ItemAxisQuantititesInput({
  option,
  focus,
  quantityAxis,
  buyInventory,
  template_color,
  itemQuantity,
  skuInventory,
  onChange,
  handleKeyDown,
  onUpdateQuantity,
  onFocus,
  textAlign='',
  placeholder='',
  allowMultipleFreeItem,
  freeItemsCount,
  is_shop_free,
  handleMultiFreeItemErrorMessage
}) {
  const totalQuantity = useSelector(
    state => Object.values(state.temp.cart)
      .flat()
      .reduce((sum, item) => sum + item.quantity, 0)
  );
  const {currentFreeItemsQuantity, errorMessage} = useFreeItemQuantityChecker(itemQuantity, totalQuantity, freeItemsCount);

  useEffect(()=> {
    if (is_shop_free == 1 && allowMultipleFreeItem){
      handleMultiFreeItemErrorMessage(errorMessage);
    }
}, [errorMessage]);

  return (
    <Input
      type="text"
      value={is_shop_free == 1 && allowMultipleFreeItem ? currentFreeItemsQuantity : itemQuantity}
      onChange={onChange(quantityAxis, option)}
      onKeyDown={handleKeyDown(quantityAxis, option, currentFreeItemsQuantity)}
      onBlur={() => onUpdateQuantity(quantityAxis, option, currentFreeItemsQuantity)}
      onFocus={() => onFocus(`${quantityAxis}-${option}`)}
      style={{
        marginBottom: 0,
        borderRadius: 5,
        ...(focus === `${quantityAxis}-${option}` ? { border: `1px solid ${template_color}` } : {}),
        textAlign,
      }}
      disabled={!buyInventory && skuInventory === 0}
      placeholder={placeholder}
      className="item_axis_quantity_input"
      id={`item_axis_quantity_input-${quantityAxis}-${option}`}
    />
  );
}

export default CartItemSkuSelector;
