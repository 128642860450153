import React, { Component } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router-dom";
import {
  Csku,
  Row,
  ShoppingCartIcon,
  SidePanel,
  colors,
  fontFamilies,
} from "@commonsku/styles";

import {
  createEditItem,
  createCancelEditCartItem,
  createDeleteCartItem,
  createSaveCartItem,
  createUpdateBreakdown,
} from "../../../actions/shop";
import { TemplateButton } from "./../helpers";
import {
  getOrderTotal,
  isArtworkOk,
  isMinimumQuantityOk,
  isShopQuantityOk,
  isTotalOk,
} from "./helpers";

import CartItem from "./../CartItem";
import CartItemsContainer from "./CartItemsContainer";
import CartHeader from "./CartHeader";
import CartFooter from "./CartFooter";
import { withRouter } from "../../helpers";
import { publicViewContext } from "../../../context/PublicViewProvider";
import { selectState } from "../../../redux/hooks/product_questions";

export const DEFAULT_COLOR = "#5ca3b6";

export class Cart extends Component {
  static contextType = publicViewContext;
  /**
   * @type {import('../../../context/PublicViewProvider').TPublicViewContext}
   */
  context;

  constructor(props) {
    super(props);

    this.state = {
      showDetailsItemIds: [].concat(this.props.showDetailsItemId || []),
    };
    this.onCheckout = this.onCheckout.bind(this);
    this.onToggleShowItemDetails = this.onToggleShowItemDetails.bind(this);
    this.onToggleShowAllItemsDetails =
      this.onToggleShowAllItemsDetails.bind(this);
    this.onUpdateBreakdown = this.onUpdateBreakdown.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showCart && !this.props.showCart) {
      this.setState({ showDetailsItemIds: [] });
      this.props.onCancelEditCartItem();
    } else if (!prevProps.showCart && this.props.showCart) {
      const stateIds = this.state.showDetailsItemIds;
      if (
        this.props.showDetailsItemId &&
        !stateIds.includes(this.props.showDetailsItemId)
      ) {
        this.setState({
          showDetailsItemIds: [
            ...this.state.showDetailsItemIds,
            this.props.showDetailsItemId,
          ],
        });
        const id = `cart_item-cart-${this.props.showDetailsItemId}`;
        const elem = document.getElementById(id);
        elem &&
          elem.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
      }
    }
  }

  onUpdateBreakdown(item_id, color_id, size_id, sku_id, quantity) {
    const { dispatch } = this.props;
    const { entityIsFree, allowMultipleFreeItem, freeItemsCount } = this.context.publicViewEntity;
    const shopId = this.props.shop.shop_id;
    dispatch(
      createUpdateBreakdown(
        item_id,
        color_id,
        size_id,
        sku_id,
        quantity,
        entityIsFree,
        shopId,
        freeItemsCount > 1 && allowMultipleFreeItem,
        true
      )
    );
  }

  isItemShowDetails(itemId) {
    return this.state.showDetailsItemIds.includes(itemId);
  }

  onToggleShowAllItemsDetails() {
    const { cart } = this.props;

    if (cart.items.length === 0) {
      return;
    }

    let newState = [];
    if (cart.items.length !== this.state.showDetailsItemIds.length) {
      newState = cart.items.map((v) => v.item_id);
    }
    this.setState({ showDetailsItemIds: newState });
  }

  onToggleShowItemDetails(itemId) {
    const idx = this.state.showDetailsItemIds.findIndex((v) => v === itemId);
    let newState = [];
    if (idx === -1) {
      newState = [...this.state.showDetailsItemIds, itemId];
    } else {
      newState = [
        ...this.state.showDetailsItemIds.slice(0, idx),
        ...this.state.showDetailsItemIds.slice(idx + 1),
      ];
    }
    this.setState({ showDetailsItemIds: newState });
  }

  renderCartItem(item) {
    const {
      onEditCartItem,
      onCancelEditCartItem,
      onDeleteCartItem,
      onSaveCartItem,
    } = this.props;
    const {
      editingCartItemId,
      publicViewEntity: {
        entityOrder: order,
        entityIsFree,
        entityAggregate,
        entityBuyInventory,
        entityShowInventory,
        entityForceMinimumQty,
        entityClientId,
        entityTenantId,
        freeItemsCount,
        allowMultipleFreeItem,
      },
      publicViewTemplate: { templateColor },
    } = this.context;
    const productQuestions = this.props.productQuestions;
    const isEditing = item.item_id === editingCartItemId;
    const isCollapsed = !this.isItemShowDetails(item.item_id);
    const shopId = this.props.shop.shop_id;

    return (
      <CartItem
        key={item.item_id}
        item={item}
        is_shop_free={entityIsFree}
        force_minimum_qty={entityForceMinimumQty}
        buy_inventory={entityBuyInventory}
        aggregate={entityAggregate}
        client_id={entityClientId}
        tenant_id={entityTenantId}
        isEditing={isEditing}
        isCollapsed={isCollapsed}
        onToggleShowDetails={() => this.onToggleShowItemDetails(item.item_id)}
        onEditCartItem={onEditCartItem}
        onDeleteCartItem={onDeleteCartItem}
        onCancelEditCartItem={onCancelEditCartItem}
        onSaveCartItem={onSaveCartItem}
        onUpdateBreakdown={this.onUpdateBreakdown}
        template_color={templateColor}
        currency_symbol={order.currency_symbol}
        buyInventory={!!entityBuyInventory}
        showInventory={!!entityShowInventory}
        showOnlyBreakdowns
        showArtworks
        showHeader
        isInCart
        productQuestions={productQuestions}
        shop_id={shopId}
        freeItemsCount={freeItemsCount}
        allowMultipleFreeItem={allowMultipleFreeItem}
      />
    );
  }

  onCheckout() {
    const { navigate, onClose } = this.props;
    const {
      publicViewEntity: { baseUrl, entityBuyInventory },
    } = this.context;
    onClose();
    navigate(
      `${baseUrl}/checkout` +
        (!!entityBuyInventory ? "?buy_inventory=true" : "")
    );
  }

  render() {
    const { cart, fullHeight = false } = this.props;
    const {
      publicViewEntity: {
        basePathname,
        entityClientId: client_id,
        entityAggregate: aggregate,
        perCheckoutLimit: per_checkout_limit,
        entityBuyInventory: buy_inventory,
        entityForceMinimumQty: force_minimum_qty,
        entityOrder: order,
        entityIsFree,
        freeItemsCount,
        entity: {
          free_items_count=1,
          allow_multiple_free_item=0,
        },
      },
      publicViewTemplate: { templateColor: template_color },
    } = this.context;

    const itemInCart = !!cart.items.length;
    const orderTotal = getOrderTotal({ cart });
    const minimumQuantityOk = isMinimumQuantityOk({
      buy_inventory,
      force_minimum_qty,
      aggregate,
      cart,
    });
    const artworkOk = isArtworkOk({ cart, client_id });
    const totalOk = isTotalOk({ orderTotal, per_checkout_limit });
    const qtyOK = isShopQuantityOk({ is_free_shop: entityIsFree, items: cart.items, free_items_count, allow_multiple_free_item });
    const totalQuantity = cart.items.reduce((sum, item) => sum + item.quantity, 0);
    const isKeepShoppingDisabled = !qtyOK || (+entityIsFree === 1 && totalQuantity === freeItemsCount);

    const isAnyItemOpen = this.state.showDetailsItemIds.length > 0;
    const isCheckout = matchPath(
      { path: `${basePathname}/:parent_id/checkout` },
      window.location.pathname
    );
    const isEditingAnItem =
      cart.items.filter((it) => it.item_id === this.props.editingCartItemId)
        .length > 0;
    const productQuestionsError = this.props.productQuestions?.error || {};
    const hasError = Object.keys(productQuestionsError).length > 0;
    const checkoutDisabled = !(minimumQuantityOk && artworkOk && totalOk && qtyOK && !hasError);

    return (
      <Csku
        as={SidePanel}
        visible={this.props.showCart}
        pb={10}
        style={{
          xs: {
            width: 624,
            background: colors.neutrals[20],
            boxShadow: "0 0 28px rgb(61 79 90 / 9%)",
            overflow: "auto",
          },
          md: {
            ...(fullHeight
              ? {}
              : {
                  marginTop: "76.5px !important",
                  paddingBottom: "76.5px !important",
                }),
            overflow: "hidden",
          },
        }}
        noHeader
        header={<></>}
      >
        <div
          className="shop"
          style={{
            background: colors.neutrals[20],
            paddingBottom: 0,
          }}
        >
          <Row>
            <CartHeader
              cart={cart}
              itemInCart={itemInCart}
              isEditingAnItem={isEditingAnItem}
              template_color={template_color}
              showDetailsItemIds={this.state.showDetailsItemIds}
              onToggleShowAllItemsDetails={this.onToggleShowAllItemsDetails}
              isFreeShop={entityIsFree}
              freeItemsCount={freeItemsCount}
            />
            <CartItemsContainer
              fullHeight={fullHeight}
              itemInCart={itemInCart}
              cartItems={cart.items}
              template_color={template_color}
              showDetailsItemIds={this.state.showDetailsItemIds}
            >
              {itemInCart ? (
                cart.items.map((i, index) => {
                  if (index !== 0) {
                    return [
                      <div
                        key={`${i.item_id}-separator`}
                        style={{
                          borderBottom: "2px solid var(--color-neutrals-60)",
                          marginLeft: 16,
                          marginRight: 16,
                        }}
                      />,
                      this.renderCartItem(i),
                    ];
                  } else {
                    return this.renderCartItem(i);
                  }
                })
              ) : (
                <div style={{ textAlign: "center", paddingTop: 36 }}>
                  <ShoppingCartIcon
                    size="huge"
                    color="var(--color-neutrals-60)"
                    width={128}
                    height={130}
                  />
                  <p
                    style={{
                      color: "var(--color-neutrals-80)",
                      fontFamily: fontFamilies.regular,
                      textAlign: "center",
                      padding: "16px 16px 0px 16px",
                      margin: 0,
                    }}
                  >
                    Your cart is empty!
                  </p>
                  <p
                    style={{
                      color: "var(--color-neutrals-80)",
                      fontFamily: fontFamilies.regular,
                      textAlign: "center",
                      paddingBottom: 24,
                    }}
                  >
                    Add products to proceed to checkout
                  </p>
                  <TemplateButton
                    id="btn"
                    className="keep_shopping_btn-1"
                    template_color={template_color}
                    style={{
                      marginRight: 10,
                      width: "90%",
                      maxWidth: 450,
                      textAlign: "center",
                    }}
                    onClick={() => {
                      if(isKeepShoppingDisabled) return;
                      this.props.onClose();
                    }}
                  >
                    Keep Shopping
                  </TemplateButton>
                </div>
              )}
            </CartItemsContainer>
            <CartFooter
              cart={cart}
              order={order}
              totalOk={totalOk}
              qtyOK={qtyOK}
              freeItemsCount={freeItemsCount}
              isCheckout={isCheckout}
              orderTotal={orderTotal}
              itemInCart={itemInCart}
              isAnyItemOpen={isAnyItemOpen}
              template_color={template_color}
              checkoutDisabled={checkoutDisabled}
              onClose={this.props.onClose}
              onCheckout={this.onCheckout}
            />
          </Row>
        </div>
      </Csku>
    );
  }
}

export const mapStateToProps = (state, ownProps) => ({
  editingCartItemId: state.temp.editingCartItemId,
  productQuestions: selectState(state),
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  onEditCartItem: (item_id) =>
    dispatch(
      createEditItem(
        ownProps.params.shop_id,
        item_id,
        ownProps.is_shop_free,
        true,
      )
    ),
  onCancelEditCartItem: () => dispatch(createCancelEditCartItem()),
  onDeleteCartItem: (item_id) =>
    dispatch(createDeleteCartItem(item_id, ownProps.params.shop_id)),
  onSaveCartItem: (item_id, updates) =>
    dispatch(createSaveCartItem(item_id, updates)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
